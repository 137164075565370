/* AK Style Variables */
@import '../colors';

$primary:       #305a8c;
$secondary:     #205a9c;
$light:         #d8d8d8;

$font-family-base: 'Open Sans', sans-serif;

/* Primary Page-Styling Variables */
$body-bg:                           #495058;
$body-color:                        #222;
$btn-pop-bg:                        $secondary;
$btn-upload-color:                  $secondary;

$footer-bg:                         $primary;

$link-color:                       #0000FF;

/* Proccess Page Styling */
$process-page-themes: (
  "claim": $primary,
  "holder": $primary
);

$dropdown-bg:                       #305a8c;
$dropdown-hover-bg:                 none;
$dropdown-divider-bg:               #777;

$dropdown-link-color:               #fff;
$dropdown-link-hover-color:         #fff;
$dropdown-link-hover-bg:            #2F5983;

$tooltip-icon-color:                $primary;
$mobile-navbar-bg:                  #495058;
$static-page-header-color:           $primary;
$static-page-subtitle-color:        #333;

/* Image Size & Image Source Variables */
$banner-image-url:                  url('/images/Banner_Image.jpg');

$claim-header-logo-url:             url('/images/process_logo.png');
$claim-header-logo-height:          56px;
$claim-header-logo-width:           200px;

$holder-header-logo-url:             $claim-header-logo-url;
$holder-header-logo-height:          $claim-header-logo-height;
$holder-header-logo-width:           $claim-header-logo-width;

$home-logo-url:                     url('/images/static_logo.png');
$home-logo-width:                   304px;
$home-logo-height:                  80px;
$home-logo-resize-value-map:        (width: 80px,
                                     height: $home-logo-height,
                                     background-image: url('/images/state_seal.png'));

$treasurer-logo-url:                $home-logo-url;
$treasurer-logo-width:              $home-logo-width;
$treasurer-logo-height:             $home-logo-height;
$treasurer-logo-resize-value-map:   $home-logo-resize-value-map;

$footer-logo-url:                   url('/images/state_seal.png');
$footer-logo-width:                 150px;
$footer-logo-height:                150px;

$claimform-image-url:               url('/images/Sample_Claim_Form.png');

$icon-email-url:                    none;
$icon-facebook-url:                 url('/images/facebook.png');
$icon-instagram-url:                none;
$icon-twitter-url:                  none;
$icon-youtube-url:                  none;
$icon-linkedin-url:                 none;

$social-icon-width:                 50px;
$social-icon-height:                50px;

.navbar-nav {
  font-size: 20px;
}

.btn-action {
  color: $white !important;
}

.btn-pop {
  color: $white !important;
}


