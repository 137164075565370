//** Global theme-specific variables for Home, Claim, and Holder Pages.
//## Main Style/Theme Colors
//## Home Pages and Static Pages
//## Process Pages

/* * * * * * * * * * * * * *
 * Main Style/Theme Colors *
 * * * * * * * * * * * * * */

// Determines the main color for a theme type

// Colors for these components used on home page and static pages
$header-bg: $primary !default;
$footer-bg: $primary !default;
$navbar-bg: $primary !default;
$mobile-navbar-bg: $navbar-bg !default;
$mobile-nav-link-color: $white !default;

// Kelmar Logo (Note: Some states can have a custom kelmar logo, but this is all states default/fallback)
$kelmar-logo-url: url('/images/poweredbykelmar.png') !default;
$kelmar-logo-width: 152px !default;
$kelmar-logo-height: 20px !default;
$kelmar-logo-force-dark: false !default;
$kelmar-logo-brightness-filter: 50% !default;

/* * * * * * * * * * * * * * * *
 * Home Pages and Static Pages *
 * * * * * * * * * * * * * * * */

// Banner Style Variables
$banner-button-bg: $secondary !default;
$ticker-bg: $primary !default;
$ticker-color: $white !default;

// Home Page Body-Content Style Variables
$home-page-heading-color: $primary !default; // Non-Banner Headings on Home Page
$home-page-button-color: $secondary !default;
$page-button-color: $primary !default;
$body-background-image-url: none !default;

// Static Pages Style Variables
$static-page-header-color: $secondary !default; // h3
$static-page-title-color: $primary !default; // h4, h5
$static-page-subtitle-color: #5c6269 !default; // h6

$footer-logo-url: none !default;
$footer-logo-height: 100px !default;
$footer-logo-width: 100px !default;

$footer-nav-link-color: $white !default;
$nav-tabs-link-color: $nav-tabs-border-color !default;
$nav-tabs-link-hover-color: white !default;


$btn-mbl-color: #eee !default;
$btn-upload-text-color: nth(adjust-contrast($primary, $white), 2) !default;
$btn-upload-color: nth(adjust-contrast($primary, $btn-upload-text-color), 1) !default;

/* * * * * * * * *
 * Process Pages *
 * * * * * * * * */

$process-page-themes: (
  "claim": $primary,
  "holder": $primary
) !default;

$custom-bg-variants: () !default;

//## General Styling for Process Page Components
$pp-color: null !default;
$process-badge-header-bg: $primary !default;
$btn-action-bg: $secondary !default;
$btn-home-bg: $white !default;
$btn-nav-bg: null !default;
$btn-pop-bg: $pink !default;
$tooltip-icon-color: $warning !default;
$tooltip-header-bg-color: $primary !default;
$btn-payment-search-bg: null !default;

// Claims Process Styling Variables
$btn-prop-claim-icon-size: inherit !default;
$btn-prop-share-icon-size: inherit !default;

//** Persistent style values among all states. Can be customized per state within their style sheet.
//   Section Contents:
//## Image Resize Value-Maps for Header Images
//## Main Style Colors for the Two Process Page Themes (Claim/Holder)
//## General Styling for Process Page Components

//## Image Resize Value-Maps for Header Images
$claim-header-logo-resize-value-map: (width: scale-by-percent($claim-header-logo-width, 25%),
  height: scale-by-percent($claim-header-logo-height, 25%),
  background-image: $claim-header-logo-url) !default;

$holder-header-logo-resize-value-map: (width: scale-by-percent($holder-header-logo-width, 25%),
  height: scale-by-percent($holder-header-logo-height, 25%),
  background-image: $holder-header-logo-url) !default;

$footer-logo-resize-value-map: (width: scale-by-percent($footer-logo-width, 25%),
  height: scale-by-percent($footer-logo-height, 25%),
  background-image: $footer-logo-url) !default;

/* * * * * * * * * * * *
 *   Material Icons    *
 * * * * * * * * * * * */
$md-icon-color: $white !default;
$md-icon-bg: $primary !default;

$tooltip-text-color: $white !default;

$icon-pinterest-url: none !default;
