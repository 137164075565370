.static-page {
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;

  // #Content
  > .container-fluid {
    flex: 1 0 auto;
  }

  .sp-title {
    text-transform: uppercase;
    color: $static-page-header-color;
  }

  header{
    background-color: $header-bg;

    nav.navbar {
      background-color: $navbar-bg;

      .dropdown {
        > [aria-expanded="true"] {
          background-color: $dropdown-hover-bg;
          + .dropdown-menu {
            display: block !important;
          }
        }

        &:hover, &:focus {

          background-color: $dropdown-hover-bg;
          .dropdown-menu {
            display: block;
          }
        }

        .dropdown-menu {
          min-width: $dropdown-min-width;
          max-width: auto;
          border: 1px solid;

          &.last {
            right: 0;
            left: auto;
          }

          .dropdown-item {
            &:hover, &:focus {
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  footer {
    position: relative;
    min-height: 220px;
    padding: 2rem 3rem 3rem;

    background-color: $footer-bg;

    .nav-link {
      color: $footer-nav-link-color;
      padding: .12rem 0;

      &:hover {
        color: darken($color:$footer-nav-link-color, $amount: 15%);
      }
    }

    .abs-kelmar-logo {
      position: absolute;
      bottom: 0;
      left: 0;
      margin: .5rem;
    }

    @include media-breakpoint-down(sm) {
      min-height: 200px;
    }
  }

  // #Main (Home) Page Content Style
  .home-page > .home-banner {
    .card {
      margin: 0 -15px;

      >.card-img {
        background-image: $banner-image-url;
        min-height: 400px;
        width: 100%;
        background-size: cover;
      }

      .jumbotron {
        margin-bottom: 0px;
        border-radius: 0;

        >.lead {
          line-height: 1;
          font-weight: normal;
        }
        .btn-lg {
          font-size: 1.5rem;
        }
      }
    }
  }

  >.sp-content {
    min-height: 1px;

    >.sp-title {
      margin-top: 2rem;
    }
  }

  sws-mbl-sidebar {
    @include contrast-navbar($mobile-navbar-bg);
    background-color: $mobile-navbar-bg;
    z-index: 2000;
    right: 0;
    overflow-y: scroll;

    @include media-breakpoint-down(xs) {
      width: 100%!important;
    }

    .navbar {

      .close {
        font-size: 2.5rem;
        cursor: pointer;
        color: $mobile-nav-link-color;
      }

      .nav-link {
        font-size: 0.95rem;
        color: $mobile-nav-link-color;
        padding: 0;

        &:hover {
          color: darken($color:$mobile-nav-link-color, $amount: 15%);
          text-decoration: underline;
        }
      }
    }
  }
}

.btn-mbl-toggle {
  @extend .btn, .btn-lg;

  background-color: transparent;
  border: 2px solid $btn-mbl-color;
  border-radius: 10px;
  color: $btn-mbl-color;
  font-weight: 700;

    &:hover {
      cursor: pointer;
      background-color: $btn-mbl-color;
      color: $header-bg;
    }
}

/* TX Specific Component */
#skiptocontent a {
  padding: 6px;
  position: absolute;
  top: -40px;
  left: 0;
  color: #fff;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: #b60000;
  -webkit-transition: top 1s ease-out;
  transition: top 1s ease-out;
  z-index: 100;

  &:focus {
    position: absolute;
    left: 0;
    top: 0;
    outline: 0;
    -webkit-transition: top .1s ease-in;
    transition: top .1s ease-in;
  }
}

@media (prefers-reduced-motion) {
  #skiptocontent a {
    &:focus {
      -webkit-transition: none ease-in !important;
      transition: none ease-in !important;
    }
    &:hover {
      -webkit-transition: none ease-in !important;
      transition: none ease-in !important;
    }
    -webkit-transition: none ease-out !important;
    transition: none ease-out !important;
  }
}
