/*
 *------------------------------------------
 *   Bootstrap Theme Color Merges (No Overrides)
 *-----------------------------------------
 */

$success: #87D4AA !default;
$info:    #D4EAF6 !default;
$warning: #ffb74c !default;
$danger:  #FF7D7D !default;
$light:   #eee    !default;
$summary: #feefd8 !default;

$theme-colors: (
  "summary": $summary
) !default;

$fade-dark:      rgba(0,0,0,.6);
$fade-light:     hsla(0,8%,97%,.6);
$fade-red:       rgba(137,43,74,.82);
$fade-blue:      rgba(52,71,89, .8);
$blur-gray:      hsla(207,5%,43%,.8);
$transparent:    rgba(255, 255, 255, 0);

$color-tint: 8 !default;
$color-tint-dark: $color-tint !default;
$color-tint-darker: $color-tint * 2 !default;
$color-tint-light: $color-tint !default;
$color-tint-lighter: $color-tint * 2 !default;

$colors: (
  "fade-dark" : $fade-dark,
  "fade-light" : $fade-light,
  "fade-blue" : $fade-blue,
  "fade-red" : $fade-red,
  "blur-gray" : $blur-gray,
  "transparent" : $transparent
);

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;
$gray:     #808285 !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;
