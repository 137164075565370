//** Bootstrap variables persistent among majority of states

@import 'colors';
$zindex-modal:                       1060 !default;
$font-size-base:                    .875rem !default;
$font-size-lg:                      ($font-size-base * 1.3) !default;
$h1-font-size:                      $font-size-base * 2.25 !default;

$alert-bg-level:                    -7 !default;
$alert-border-level:                -6 !default;

$breadcrumb-divider:                none;

$btn-font-weight:                   700 !default;
$btn-link-disabled-color:           #fff !default;
$btn-focus-width:                   0;

$card-bg:                        		lighten($light, 2%) !default;
$card-cap-bg:                       $light !default;
$card-border-width:                 0px;
$card-border-radius:                0px;

$component-active-color:            #fff !default;
$component-active-bg:               #337ab7 !default;

$custom-control-indicator-size:     1.5rem !default;
$custom-control-gutter:             3rem !default;
$custom-control-spacer-x:           1rem !default;

//Navbar Dropdown Menu
$dropdown-padding-y:                0rem;
$dropdown-item-padding-y:           0.75rem;
$dropdown-item-padding-x:           0.625rem;
$dropdown-min-width:                15rem !default;
$dropdown-link-hover-bg:            $gray-100 !default;
$dropdown-spacer:                   0rem;

$dropdown-link-active-bg:           $dropdown-link-hover-bg !default;
$dropdown-border-color:             none;
$dropdown-border-radius:            0rem;

$dropdown-box-shadow:               0 .5rem 1rem rgba($black, .175) !default;

$headings-font-weight:              700;
$heading-line-height:               1.1;
$headings-margin-bottom:            1.5rem;

$footer-logo-breakdown-size:        100 !default;

$hr-border-color:                   rgba($white, .9) !default;
$hr-border-width:                   1.25px !default;
$hr-margin-y:                       0.85rem !default;

$jumbotron-padding:                 1rem !default;
$jumbotron-bg:                      $fade-dark !default;

$lead-font-size:                   ($font-size-base * 1.5) !default;
$lead-font-weight:                  700 !default;

$link-color:                        #0d94e2 !default;
$link-hover-color:                  darken($link-color, 15%) !default;
$link-hover-decoration:             none;
$link-decoration:                   none;

$navbar-dark-color:                 $white;

$nav-link-padding-y:                1rem;
$nav-link-padding-x:                1.75rem;
$navbar-nav-link-padding-x:         2.2rem;

$nav-tabs-border-color:             $primary !default;
$nav-tabs-hover-bg-color:           $primary !default;
$nav-tabs-link-hover-border-color:  $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-border-color !default;
$nav-tabs-link-active-color:        #fff !default;
$nav-tabs-link-active-bg:           $primary !default;
$nav-tabs-link-active-border-color: $nav-tabs-link-active-bg $nav-tabs-link-active-bg $nav-tabs-link-active-bg !default;

$table-head-bg:                     darken($light, 5%) !default;
$table-head-color:                  $gray-800 !default;
$table-bg:                          $white !default;

$yiq-text-light:                    #fff !default;
$yiq-text-dark:                     black !default;

$sizes: (
  15: 15%,
  33: 33%,
  35: 35%,
  66: 66%
);

// Class Overrides
  .card-body {
    min-height: 1px;
  }

.dropdown-item {
  border-bottom: 1px solid $dropdown-divider-bg !important;

  &:last-of-type {
    border: none !important;
  }
}
