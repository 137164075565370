//** These are generic reusable mixins that help with tasks used in many elements

/// Compass CSS3 mixins don’t yet handle vendor prefixes for CSS animations,
/// so this is a very simple mixin to simulate that functionality.
@mixin animation($name, $duration: 1000ms, $iterations: infinite, $timing-function: ease, $delay: 0ms) {
  // There is a FF bug that requires all time values to have units, even 0 !!!!!!
  -webkit-animation: $name $duration $iterations $timing-function $delay;
  -moz-animation: $name $duration $iterations $timing-function $delay;
  -o-animation: $name $duration $iterations $timing-function $delay;
  animation: $name $duration $iterations $timing-function $delay;
}

/// Mixin helping defining both `width` and `height` simultaneously.
///
/// @access public
///
/// @param {Length} $width - Element’s `width`
/// @param {Length} $height [$width] - Element’s `height`
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}


@mixin extend-tab($tab-count){
  margin-left: #{$tab-count * 4}em;

}