//Mixins
@import "mixins";

//Partials
@import "partials/layout";
@import "partials/icons";
@import "partials/logos";
@import "partials/themes";
@import "static";
@import "process";
@import "forms";

//** This file contains mostly formatting and general styling for elements.
//** All styles contigent on the state are done in _themes.scss

@include media-breakpoint-down(xs) {
  ngb-pagination li {

    &:not(:first-child):not(:last-child) {
      display: none;
    }

    &:first-child {
      margin-right: auto;
    }

    &:last-child {
      margin-left: auto;
    }
  }
}

sws-carousel {
  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
  }

  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none
    }
  }

  .carousel-item.active, .carousel-item-next, .carousel-item-prev {
    display: block
  }

  .carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
    transform: translate(100%)
  }

  .carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
    transform: translate(-100%)
  }

  .carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none
  }

  .carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
    z-index: 1;
    opacity: 1
  }

  .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
  }

  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
      transition: none
    }
  }

  .visually-hidden {
    @extend .sr-only;
  }


  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none
  }

  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    padding: 0;
    margin-right: 1px;
    margin-left: 1px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #343d4669;
    transition: opacity .6s ease
  }

  .carousel-indicators .active {
    background-color: #343d46;
    opacity: 1
  }
}

sws-cart-preview {
  position: relative;

  .cart-preview {
    display: block;
    position: absolute;
    z-index: 1000;
    min-width: 360px;
    background-color: #4f4d4d;
    right: 0;
  }

  .list-scrollable {
    max-height: 150px;
  }
}

sws-add-to-cart {
  .btn {
    transition: none;
  }
}

sws-go-to-top-button {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0 3rem 2rem 0;
  z-index: 10000;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  perspective: 10000;
  -webkit-perspective: 10000;

  > button.btn {
    background-color: white;
    opacity: .6;

    &:hover {
      opacity: .9;
    }
  }
}

sws-property-list {
  table {
    @include media-breakpoint-down(sm) {
      border-collapse: separate;
      border-spacing: 0 15px;

      > tbody {
        > tr {
          background-color: #bbb;

          > td {
            display: block;
            width: 100%;
            padding: 0 10px 10px 10px;
            border: none !important;

            > b {
              display: inline-block;
              width: 35%;
            }

            > span {
              display: inline-block;
              width: 62%
            }
          }
        }
      }
    }
  }
}

// Tooltip hover-badge found on process pages
sws-info-tooltip {
  vertical-align: text-top;
  line-height: .5;

  .card {
    @extend .card-tooltip;
    position: absolute;

    max-width: 300px;
    min-width: 225px;
    border-color: $tooltip-header-bg-color;
    background-color: $white;
    display: block;

    > .card-header {
      background-color: $tooltip-header-bg-color;
      margin: -1px;
    }
  }

  .badge {
    position: relative;
    font-size: 1rem;
    background-color: $tooltip-icon-color;

    @include media-breakpoint-down(sm) {
      position: inherit;
    }
  }

}

sws-non-remitted-button {
  > div {
    > .card {
      @extend .card-tooltip;
      display: none;
      position: absolute;
      max-width: 300px;
      border-color: $primary;
      top: auto !important;
      left: auto !important;
    }
  
    .btn:hover ~ .card,
    > .card:hover {
      display: inline-block;
    }
  }

  .popover {
    max-width: 66% !important;
  }
}

sws-process-page {
  // Badges for document uploads
  #file-inputs, #file-section {
    .badge {
      cursor: pointer;
    }
  }
}

sws-property-list {

  @include media-breakpoint-down(md) {
    // Make property search results span entire width of mbl device
    colgroup > col:first-child {
      width: 100%;
    }
  }


  @include media-breakpoint-up(md) {
    // Make property search results span 6% of column width
    colgroup > col:first-child {
      width: 6%;
    }
  }

  //Removes the borders around <td> elements on mobile devices
  table {
    @include media-breakpoint-down(md) {
      &.table-bordered, td {
        border: none;
      }
    }

    > tbody {
      tr {
        @include size(auto, 51px);
        max-height: 51px;
        min-height: 51px;

        &.card {
          background-color: $table-bg;
        }
      }
    }
  }
}

sws-validation-tooltip, .validation-tooltip {
  @extend .shadow;
  position: absolute;
  top: 0;
  right: -180px;

  display: none;
  width: 180px;
  padding: 5px;

  background-color: #fbb03b;
  border: 2px solid #c26b1e;
  border-radius: 5px;

  font-size: .8rem;
  font-weight: bold;
  z-index: 2000;

  @include media-breakpoint-down(sm) {
    top: auto;
    bottom: 35px;
    right: 5px;
  }
}

sws-home-ticker {
  display: inline-block;
  padding: .5rem 1.5rem;
  background-color: $ticker-bg;
  color: $ticker-color;
}

sws-twitter-timeline {

  .twitter-container {
    overflow-y: scroll;
  }

}

sws-process-page {

  .custom-control-input:focus ~ .custom-control-label {
    ::after {
      border-color: 2px solid #85FF00 !important;
    }

    ::before {
      box-shadow: none;
    }
  }

  a, .nav-link, form .btn, input, .badge, #alert, select, table .btn, div .btn, process-nav .btn {
    &:focus:not(:active), &:focus-within:not(:active), &:focus:not(:hover) {
      outline: 2px solid #85FF00 !important;

      > span[class^="icon"] {
        outline: inherit !important;
        border: inherit !important;
      }
    }
  }

  process-nav {
    display: block;
    position: relative;
    padding: 1px;
    text-align: center;

    .btn {
      &:first-of-type {
        float: left;
      }

      &:last-of-type {
        float: right;
      }
    }

    .btn-hidden {
      visibility: hidden;
      cursor: default;
    }

    @include media-breakpoint-down(md) {
      .pp-title {
        margin-top: 4rem;
      }
    }

    @include media-breakpoint-down(sm) {
      .pp-title {
        margin-top: 5rem;
      }
    }

    @include media-breakpoint-down(xs) {
      .pp-title {
        margin-top: 6rem;
      }
    }
  }
}

sws-claim-address-modal {
  .custom-radio .custom-control-label:before {
    background-color: $white;
  }

  .btn-nav {
    @extend .btn-lg;
    @include fully-colored-element(if($btn-nav-bg != null, $btn-nav-bg, $primary));

    > .material-icons {
      font-size: 36px;
      line-height: 24px;
    }

    > span {
      vertical-align: middle;
    }
  }
}

.file-preview {
  height: 100%;
  min-height: 40px;
  border: 1px solid $gray-500;
  padding: .6rem;
  white-space: nowrap;

  > .file-preview-name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.file-selected {
    background-color: $gray-300
  }

  &.file-over {
    background: white;
    border-radius: 5px;
    border: 2px dashed $gray-300;
    border-image: none;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &.ng-invalid {
    background-color: $danger;
    border-color: darken($danger, 25%);
  }
}

.list-scrollable {
  overflow-y: scroll;
  direction: rtl;

  &::-webkit-scrollbar {
    background: #4f4d4d;
  }

  &::-webkit-scrollbar-track {
    background: #4f4d4d;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #777;
    border-radius: 1ex;
  }

  li {
    direction: ltr;
  }
}

sws-static-wrap {
  a, .nav-link, form .btn {
    &:focus:not(:active), &:focus-within:not(:active), &:focus:not(:hover) {
      outline: 2px solid #85FF00 !important;

      > span[class^="icon"] {
        outline: inherit !important;
        border: inherit !important;
      }
    }
  }
}

.btn-entry {
  @extend .btn-lg;
  font-size: 1rem;
  border-radius: 0px;
  text-align: left;
  min-width: 130px;
  max-width: 500px;

  @include media-breakpoint-up(lg) {
    min-width: 310px;
  }

  > span {
    @include media-breakpoint-down(xs) {
      white-space: normal;
    }
  }

  .material-icons {
    line-height: inherit;
    background-color: rgba(5, 5, 5, .15);
    font-size: 2.75rem;
    margin: -.5rem -1rem;
  }
}

.card-tooltip {
  top: -.5rem;
  left: 1.6rem;
  border-radius: 1rem;
  border-width: 3px;
  color: $body-color;
  z-index: 3000;

  .card-header {
    border-radius: .5rem .5rem 0 0;

    > .btn {
      float: right;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1rem;
      margin-right: -1rem;
      margin-top: -0.7rem;
    }
  }

  .card-body {
    border-radius: 0 0 1rem 1rem;
    font-size: .9rem;
    font-weight: normal;
    white-space: normal;
    text-align: left;
  }

  &.open-left {
    left: auto;
    right: 1.6rem;
  }

  &.open-top {
    bottom: 0 !important;
    top: auto !important;
  }

  &.open-middle {
    bottom: 50% !important;
    top: auto !important;
  }

  /* Min and max width (BS4 breakpoints) */
  @include media-breakpoint-down(sm) {
    margin: auto;
    font-weight: normal;
    // bottom: 1.6rem;
    left: 0;
    right: 0;
    width: 100%;
  }
}

//
// Pager (Removed in Bootstrap 4 so these are Bootstrap 3 styles)
//
.pager {
  @extend .clearfix;
  padding-left: 0;
  margin: $line-height-base 0;
  list-style: none;
  text-align: center;

  li {
    display: inline;

    > a,
    > span {
      display: inline-block;
      padding: 5px 14px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 15px;
    }

    > a:hover,
    > a:focus {
      text-decoration: none;
      background-color: #eee;
    }
  }

  .next {
    > a,
    > span {
      float: right;
    }
  }

  .previous {
    > a,
    > span {
      float: left;
    }
  }

  .disabled {
    > a,
    > a:hover,
    > a:focus,
    > span {
      color: #777;
      background-color: #fff;
      cursor: not-allowed;
    }
  }
}


.treasurer-image {

  @extend .d-inline-block;
  @extend .mr-4;
  @extend .mb-2;
  @extend .bg-white;
  @extend .float-left;
}



