@import '../components/mixins';

// Logo for Header on Home Page
.home-logo {
  @include sizeable-background-image($home-logo-url, $home-logo-width, $home-logo-height);
  @if variable-exists(home-logo-resize-md-value-map) {
    @include resize-img-md-bp($home-logo-resize-md-value-map...);
  }
  @if variable-exists(home-logo-resize-lg-value-map) {
    @include resize-img-lg-bp($home-logo-resize-lg-value-map...);
  }
  @if variable-exists(home-logo-resize-value-map) {
    @include resize-img-sm-bp($home-logo-resize-value-map...);
  }
}

@if variable-exists(alternate-home-logo-url) {
  .alternate-home-logo {
    @include sizeable-background-image($alternate-home-logo-url, $alternate-home-logo-width, $alternate-home-logo-height);
    @if variable-exists(alternate-home-logo-resize-md-value-map) {
      @include resize-img-md-bp($alternate-home-logo-resize-md-value-map...);
    }
    @if variable-exists(alternate-home-logo-resize-lg-value-map) {
      @include resize-img-lg-bp($alternate-home-logo-resize-lg-value-map...);
    }
    @if variable-exists(alternate-home-logo-resize-value-map) {
      @include resize-img-sm-bp($alternate-home-logo-resize-value-map...);
    }
  }
}

// Logo for Treasurer
.treasurer-logo {
  @include sizeable-background-image($treasurer-logo-url, $treasurer-logo-width, $treasurer-logo-height);
  @if variable-exists(treasurer-logo-resize-md-value-map) {
    @include resize-img-md-bp($treasurer-logo-resize-md-value-map...);
  }
  @if variable-exists(treasurer-logo-resize-lg-value-map) {
    @include resize-img-lg-bp($treasurer-logo-resize-lg-value-map...);
  }
  @if variable-exists(treasurer-logo-resize-value-map) {
    @include resize-img-sm-bp($treasurer-logo-resize-value-map...);
  }
}

// Logo for Headers on Holder Pages
.holder-header-logo {
  @include sizeable-background-image($holder-header-logo-url, $holder-header-logo-width, $holder-header-logo-height);
  @include resize-img-sm-bp($holder-header-logo-resize-value-map...);
}

// Logo for Headers on Claim Pages
.claim-header-logo {
  @include sizeable-background-image($claim-header-logo-url, $claim-header-logo-width, $claim-header-logo-height);
  @include resize-img-sm-bp($claim-header-logo-resize-value-map...);
}

// Kelmar Logo on Footer's Bottom
.abs-kelmar-logo {
  @include sizeable-background-image($kelmar-logo-url, $kelmar-logo-width, $kelmar-logo-height);
  background-repeat: no-repeat;

  // Footer is light, darken the image
  @if (color-yiq($footer-bg, true, false) == true or $kelmar-logo-force-dark == true) {
    filter: brightness($kelmar-logo-brightness-filter);
  }
}

.footer-logo {
  @include sizeable-background-image($footer-logo-url, $footer-logo-width, $footer-logo-height);
  @if variable-exists(footer-logo-resize-md-value-map) {
    @include resize-img-md-bp($footer-logo-resize-md-value-map...);
  }
  @if variable-exists(footer-logo-resize-lg-value-map) {
    @include resize-img-lg-bp($footer-logo-resize-lg-value-map...);
  }
  @if variable-exists(footer-logo-resize-value-map) {
    @include resize-img-sm-bp($footer-logo-resize-value-map...);
  }
}