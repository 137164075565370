@import '../components/mixins';

// General Form Style Overrides
label {
  font-weight: bold;

  &.col-form-label {
    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }
}
.custom-checkbox {
  >.custom-control-input {
    
    ~.custom-control-label::before {
      border: 2px solid $gray-400;
    }

    &:focus {
      ~.custom-control-label::before {
        outline: 2px solid #85FF00;
      }
    }
  }
}

// Validation Specific Styles
.ng-submitted .form-control.ng-invalid,
.form-control.ng-touched.ng-invalid,
.ng-submitted .custom-select.ng-invalid,
.custom-select.ng-touched.ng-invalid {
  @extend .is-invalid;
  border-width: 2px;

  // Tooltip should sit AFTER input element.
  +sws-validation-tooltip, +.validation-tooltip {
    display: block;
  }
}

.ng-submitted .form-group.ng-invalid,
.form-group.ng-invalid.ng-touched {
  sws-validation-tooltip, .validation-tooltip {
    display: inline-block;
  }
}

.ng-submitted .custom-control-input.ng-invalid~.custom-control-label,
.custom-control-input.ng-touched.ng-invalid~.custom-control-label {
  color: $danger;
  &::before {
    border-color: $danger;
  }
}

//TODO: fix
.custom-control-input.ng-invalid.ng-touched~.custom-control-label,
.custom-control-input.ng-touched.ng-invalid~.custom-control-label {
  color: $danger;
  &::before {
    border-color: $danger;
  }
}

form.form-submitted.ng-touched {
  // When submitted, style all legends
  &.ng-invalid, .ng-invalid, .invalid-fieldset {
    > legend {
      color: $danger;
      border-color: $danger;
    }
  }
  &.ng-valid, .ng-valid, .valid-fieldset {
    > legend {
      color: $success;
      border-color: $success;
    }
  }
}

label:lang(fr-ca) .form-label {
  &::after {
    content: ' ';
  }
}