//** Mixins for formatting elements


//## Image Resizing

/// Creates a generic social icon-variant using the state-specified
/// length and width for their social icons
///
/// @access public
///
/// @param $url the url of the icon to load
@mixin social-icon($url, $width: $social-icon-width, $height: $social-icon-height) {
  @include sizeable-background-image($url, $width, $height);
}

/// Creates a generic sizeable image which you
/// define the length and width for
///
/// @access public
///
/// @param $url the url of the image to loads
/// @param $width the width of the image
/// @param $height the height of the image
@mixin sizeable-background-image($url, $width, $height: $width) {
  @include size($width, $height);
  background-image: $url;
  display: block;
  background-size: cover;
}

/// Resizes images at the small breakpoint
///
/// @access public
///
/// @param $width the set width of the image
/// @param $height the set height of the image
/// @param $percent the percentage to shrink the element by
@mixin resize-img-sm-bp($width, $height, $background-image) {
  @include media-breakpoint-down(sm) {
    @include size($width, $height);
    background-image: $background-image;
  }
}

/// Resizes images at the medium breakpoint
///
/// @access public
///
/// @param $width the set width of the image
/// @param $height the set height of the image
/// @param $percent the percentage to shrink the element by
@mixin resize-img-md-bp($width, $height, $background-image) {
  @include media-breakpoint-down(md) {
    @include size($width, $height);
    background-image: $background-image;
  }
}

/// Resizes images at the large breakpoint
///
/// @access public
///
/// @param $width the set width of the image
/// @param $height the set height of the image
/// @param $percent the percentage to shrink the element by
@mixin resize-img-lg-bp($width, $height, $background-image) {
  @include media-breakpoint-down(lg) {
    @include size($width, $height);
    background-image: $background-image;
  }
}