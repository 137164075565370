%flex-between {
  display: flex;
  justify-content: space-between;

  > * {
    flex: 2;
    align-self: center;
    justify-content: center;

    &:first-child, &:last-child {
      flex: 1;
      -ms-flex: 1 0 auto;
    }
  }
}