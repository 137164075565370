@charset "utf-8";

//State specific style variables
@import "colors";
@import "functions";
@import "fonts";
@import "variables";
@import "bootstrap-general-overrides";

//Bootstrap variables persistent among all states
@import "theme-variables";

//Custom variables persistent among all states

//Bootstrap imports
@import "bootstrap/scss/bootstrap";

//Custom Component imports
@import "components/components";


/*
NOTE:
  * 1rem = 16px
  * 1px = 0.0625rem
*/

//Start sass helpers //////////////////////////////////////


.extend-linebreak {
  content: '\A';
  white-space: pre;
}

.extend-double-linebreak {
  content: '\A\A';
  white-space: pre;
}

.extend-underline {
  text-decoration: underline;
}


//End sass helpers //////////////////////////////////////

body, html {
  min-height: 100vh;

  @media print {
    font-size: 0.8em;
  }

  > img {
    display: block;
    height: 0;
  }
}

body {
  background-image: $body-background-image-url;
}

.root-view-wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 1140px;
  height: 100vh;

  > router-outlet ~ * {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

  > * > {
    sws-static-wrap {
      display: flex;
      min-height: 100vh;
      flex-direction: column;
    }
  }
}

/* Font Breakpoints, do we want this? */
@include media-breakpoint-down(sm) {
  html, body {
    font-size: 0.875rem;
  }
}

/* END Font Breakpoints */

input[type=text], input[type=email] {
  &::-ms-clear {
    display: none;
  }
}

.active {
  font-weight: $font-weight-bold;
}

.break-word {
  word-break: break-word;
}

.text-wrap {
  white-space: normal;
}

.dropcap::first-letter {
  font-size: 2rem;
}

.card {
  @include media-breakpoint-down(md) {
    margin-bottom: 0.75rem;
  }
}

.click-screen {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
  z-index: 1000;
}

//Removes the dropdown caret
.dropdown-toggle::after {
  display: none;
}

legend {
  border-bottom: 2px solid #d5d5d5;
}

button:disabled {
  cursor: not-allowed;
}

.faq-list {
  .card-link {
    color: $body-color;
    font-weight: bold;

    &:hover {
      text-decoration: underline !important;
    }
  }
}

// Styles for tabbed-pages (i.e. FAQ)
.nav-tabs {
  border-bottom: 5px solid $nav-tabs-border-color;
  border-radius: 0px;

  li.nav-item {
    &:not(:last-child) {
      margin-right: 10px;
      @include media-breakpoint-between(xs, sm) {
        margin-right: 0;
      }
    }

    > a.nav-link {
      @extend .text-nowrap;
      border-radius: 1px;
      color: $nav-tabs-link-color;
      border-color: $nav-tabs-border-color;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;
      padding: 10px 15px 10px 15px;

      &.active {
        color: $nav-tabs-link-active-color;
      }

      &:hover {
        color: $nav-tabs-link-hover-color;
        background-color: $nav-tabs-hover-bg-color;
      }
    }
  }
}

.screen-lock {
  > .click-screen {
    display: block;
  }
}

#img-claimform {
  background-image: $claimform-image-url;
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 40%;
}

.img-carousel {
  max-height: 225px;
  width: 100%;
}

/* Material Icon Import */

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/iconfont/MaterialIcons-Regular.eot?v=1"); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url("/fonts/iconfont/MaterialIcons-Regular.woff2?v=1") format('woff2'),
  url("/fonts/iconfont/MaterialIcons-Regular.woff?v=1") format('woff'),
  url("/fonts/iconfont/MaterialIcons-Regular.ttf?v=1") format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.4em; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  vertical-align: middle;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.circled {
  margin: auto;
  padding: 4px;
  background-color: $md-icon-bg;
  color: $md-icon-color;
  border-radius: 50%;
  line-height: 100%;
  text-align: center;
}

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}

.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

.material-icons.md-dark.circled {
  @extend .circled;
  background-color: rgba(255, 255, 255, 1);
}

.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}

.material-icons.md-light.circled {
  @extend .circled;
  background-color: rgba(0, 0, 0, 0.54);
}

.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}


/* Use for creating icons from regular characters */
.icon-circled {
  @extend .circled;

  display: inline-block;
  color: white;
  font-size: 1.4em;
  font-style: normal;
}

.text-000 {
  color: #000;
}

.text-red {
  color: #FF0009;
}


.text-large {
  font-size: 1.5rem;
}

.tooltip-text {
  color: $tooltip-text-color !important;
}

.tooltip-inner {
  background-color: unset;
}

.tooltip.show {
  opacity: 1;
}

.tooltip {
  //over ride bootstrap default because angular bootstrap and bootsrap are out of sync

  z-index: 3000 !important;
}

//to fix auto zoom on mobile
// https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone#comment-55256223
@supports (-webkit-overflow-scrolling: touch) {
  select,
  textarea,
  input, sws-dynamic-input {
    font-size: 16px !important;
  }
}

//to fix auto zoom on mobile for browsers that dont support @supports or -webkit-overflow-scrolling
// https://stackoverflow.com/questions/11387805/media-query-to-detect-if-device-is-touchscreen#answer-60021686
@media (hover: none) and (pointer: coarse) and (max-width: 768px) {
  select,
  textarea,
  input, sws-dynamic-input {
    font-size: 16px !important;
  }
}

@each $color, $value in $custom-bg-variants {
  @include bg-variant('.bg-#{$color}', $value);
}
