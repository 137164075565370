/* Social Media Sharing Icons*/
@import '../components/mixins';

.fb-share {
  display: inline-block;
  position: relative;
  height: 20px;
  min-width: 50px;
  padding: 2px 8px;
  background: #3e5b97 none repeat scroll 0 0;
  border-radius: 4px;
  font-family: arial;
  font-size: 11px;
  line-height: 16px;
  color: #fff;
  cursor: pointer;
}

.email-share {
  @extend .fb-share;
  background: #808080 none repeat scroll 0 0;
}

.icon-twitter {
  @include social-icon($icon-twitter-url);
}

.icon-email {
  @include social-icon($icon-email-url);
}

.icon-facebook {
  @include social-icon($icon-facebook-url);
}

.icon-instagram {
  @include social-icon($icon-instagram-url);
}

.icon-youtube {
  @include social-icon($icon-youtube-url);
}

.icon-linkedin {
  @include social-icon($icon-linkedin-url);
}

.icon-pinterest {
  @include social-icon($icon-pinterest-url);
}

.share-fb-icon {
  @include sizeable-background-image(url('/images/share-fb-icon.png'), 13px);
  margin: 1px 0;
}

.icon-black-check{
  $url: url('/images/icons/black_check_md.png');
  @include sizeable-background-image($url, 32px);
}

.icon-red-exclamation {
  $url: url('/images/icons/red-exclamation.png');
  @include sizeable-background-image($url, 32px);
}

.icon-white-check {
  $url: url('/images/icons/white_check_md.png');
  @include sizeable-background-image($url, 32px);
}

.icon-white-exclamation {
  $url: url('/images/icons/white_exclamation.png');
  @include sizeable-background-image($url, 32px);
}

.icon-white-error {
  $url: url('/images/icons/white-error_md.png');
  @include sizeable-background-image($url, 32px);
}
