//** SASS Mixins for Styling Elements
//** Mixins for formatting elements
// Sections:
//## Class Color Styling Mixins
//## Color Styling Mixins

//## Class Color Styling

/// Create custom breadcrumbs with specificied bg and text color
///
/// @access public
///
/// @param $bg the background color of the breadcrumbs
/// @param $color the color of the text
@mixin breadcrumb($bg, $color){
  .breadcrumb {
    background-color: $bg;
    color: $color;

    .breadcrumb-item.active {
      color: $color;
      text-decoration: underline;
    }
  }
}


/// Colors the text, background and border of the element.
/// This also automatically contrasts the text-color from the
/// background color of the element.
/// @param $color the color you want the element
@mixin fully-colored-element($color, $border:true) {

  $text-color: nth(adjust-contrast($color, $color, 1), 2);
  $background-color: nth(adjust-contrast($color, $text-color, .6), 1);
  color: $text-color !important;
  background-color: $background-color !important;

  @if $border {
    border: $border-width solid $background-color;
  }
}

/// Contrasts the mobile navbar toggler icon against the specified
/// color.  This is so the toggler icon assumes the correct background
/// image and border color.
///
/// @access public
///
/// @param {color} $bg the background color to contrast against
/// @outputs .navbar-light or .navbar-dark depending on the contrast level
@mixin contrast-navbar($bg) {
  @if(is-light($bg)){
    @extend .navbar-light;
  } @else {
    @extend .navbar-dark;
  }
}
